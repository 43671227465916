import React from "react"
import { motion } from 'framer-motion'


import getIcon  from '../utils/get-icon';
import Icon from '../components/Icon';


  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }
const Footer = ({ props }) => (
  <footer className="grid-cols-3 pb-10 pt-10 mx-24">
    <motion.div 
          className="content"
          variants={item}
          transition="easeInOut"
        >
          <hr className="block my-8" />
    </motion.div>

    <div class="grid grid-cols-2">


    <div class="grid grid-flow-row auto-rows-max">
    <div class="col-start-1">
    © Chicago Asian Writers Workshop
    </div>
    <div class="col-start-1">
    Site designed by <a class="hover:text-yellow-500" href="https://www.aprilyw.com">aprilyw</a>
    </div>
    </div>

<div class="grid grid-flow-row auto-rows-max">
      <div class="col-start-2 flex justify-end">
      Stay connected with us:
      </div>
      <div class="col-start-2 content flex justify-end space-x-2">
              <Icon name={"email"} icon={getIcon("email")} />
              <div class="ml-2"> chicagoaww@gmail.com</div>
      </div>
          <div class="col-start-2">
            <div class="content flex justify-end space-x-2">
              <a class="flex" href='https://twitter.com/chicagoaww'>
                <Icon name={"twitter"} icon={getIcon("twitter")} />
              </a>
              <a class="flex" href="https://www.facebook.com/chicagoaww/">
                <Icon name={"facebook"} icon={getIcon("facebook")} />
              </a>
              <a class="flex" href="https://www.instagram.com/chicagoaww/">
                <Icon name={"instagram"} icon={getIcon("instagram")} />
              </a>
              <div class="ml-4"> @chicagoaww</div>
            </div>

          </div>

          </div>
</div>

    
  </footer>
)


Footer.defaultProps = {
  props: ``,
}

export default Footer
