import React from 'react';

const Icon = ({ name, icon }) => (
  <svg className="w-5 h-5" viewBox={icon.viewBox}>
    <title>{name}</title>
    <path d={icon.path} />
  </svg>
);

export default Icon;
